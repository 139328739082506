export default {
  async update (Vue, id, formData) {
    await Vue.$api.call(
      'cita.update',
      {
        values: {
          idcita: id,
          titulo: formData.titulo,
          cita: formData.cita,
          fcita: formData.fcita,
          ffin: formData.ffin,
        },
      },
    )
  },
}
